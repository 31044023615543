import React, { useEffect, useRef, useState } from 'react';
import styles from './ClipCard.module.scss';
import defaultRadioKeyframe from '../../../assets/radio.jpg';
import defaultKeyframe from '../../../assets/defaultThumbnail.png';
import CountryFlag from 'src/Widgets/common/CountryFlag/CountryFlag';
import { highlightChannelName } from 'src/Widgets/common/MentionResults/MentionCard/MentionCard';
import useSentimentIcon from '../hooks/useSentimentIcon';
import { faClipboard, faCopy, faEye, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import { MessageHandler } from 'src/API/MessageHandler/MessageHandler';
import { removeOrigin } from 'src/Widgets/common/helpers';

interface ClipCardProps {
    clip: any;
    clipDetailData: any;
    clipEditorialState?: any;
    clipStates: any;
    style: any;
    isEdited?: boolean;
    isEditorial?: boolean;
    activeFilter: any;
    isMentionFindrApp: boolean;
    backFillSelectionVisible: boolean;
    setSelectedCardElements: any;
    selectedCardElements?: any;
    updateClipState: any;
    basicNotifications?: any[];
}

const formatTimeDifference = (superHitTimeTS: number, basicHitTimeTS: number) => {
    // Ensure both timestamps are in milliseconds
    const superTimeInSec = superHitTimeTS * 100;
    const basicTimeInSec = basicHitTimeTS * 100;

    // Calculate the difference in seconds
    const diffInSeconds = Math.floor((basicTimeInSec - superTimeInSec) / 100);

    // Handle negative time difference
    if (diffInSeconds <= 0) return 'Just now';

    // Handle zero time difference
    if (diffInSeconds === 0) return '0s';

    const seconds = diffInSeconds % 60;
    const minutes = Math.floor(diffInSeconds / 60) % 60;
    const hours = Math.floor(diffInSeconds / 3600);

    let timeString = '';

    if (hours > 0) {
        timeString += `${hours}h `;
    }
    if (minutes > 0) {
        timeString += `${minutes}m `;
    }
    if (seconds > 0) {
        timeString += `${seconds}s `;
    }

    return `${timeString.trim()} later`;
};

const convertUnixTimestamp = (unixTimestamp: number) => {
    //show only the time 00:00:00 24 hours format, if hours are less than 10, add a 0 in front
    const date = new Date(unixTimestamp * 1000);
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    return `${hours}:${minutes}:${seconds}`;
}

// TODO: change 'delete' to reopenReject (in api call)

const ClipCard = ({
    clip,
    clipDetailData,
    clipEditorialState,
    clipStates,
    style,
    isEdited,
    isEditorial,
    activeFilter,
    isMentionFindrApp,
    backFillSelectionVisible,
    setSelectedCardElements,
    selectedCardElements,
    basicNotifications,
    updateClipState
}: ClipCardProps) => {
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const meResponse = useAppSelector((state) => state.me);
    const messageHandlerRef = useRef(new MessageHandler());

    const sentimentIcon = useSentimentIcon(clip.sentiment);
    const { t } = useTranslation();

    const programTitle =
        clipDetailData.program?.length > 50
            ? clipDetailData.program?.substring(0, 50) + '...'
            : clipDetailData.program;

    const description =
        clipDetailData?.desc.length > (isEdited ? 200 : 400)
            ? clipDetailData.desc.substring(0, isEdited ? 200 : 400) + '...'
            : clipDetailData.desc;

    const isArabic = /[\u0600-\u06FF]/.test(description);

    const copyToClipboard = (dataToCopy: any) => {
        navigator.clipboard.writeText(dataToCopy);
        setShowCopiedMessage(true);
        setTimeout(() => {
            setShowCopiedMessage(false);
        }, 1000);
    };

    const isDuplicate = (clip: any) => clip.dupType !== null && !activeFilter.foldEnabled;

    const handleCheckboxClick = (e: React.MouseEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const isChecked = (e.target as HTMLInputElement).checked;
        if (isChecked) {
            setSelectedCardElements([...selectedCardElements, clipDetailData]);
        } else {
            setSelectedCardElements(
                selectedCardElements.filter((item: any) =>
                    item.notifID
                        ? item.notifID !== clipDetailData.notifid
                        : item.notifid !== clipDetailData.notifid
                )
            );
        }
    };

    const messageHandlerListen = (event: MessageEvent) => { 
        const { type, data } = event;
        // If the type is set-updates, update the clip state accordingly
        if (type === 'message' && data.type === 'set-updates' && clipDetailData.notifid === data.payload.notificationId && data.payload.action) {
            // update the clip state with data.payload.action
            updateClipState(clipDetailData.notifid, clipEditorialState.cas, clipStates.cl, data.payload.action, data.payload.editorialData);
        }

        // If the type is get-updates, update the clip state accordingly
        if (data.type === 'get-updates') {
            messageHandlerRef.current?.send('editorial', 'set-updates', {
                notificationId: clipDetailData.notifid,
                clipState: clipEditorialState.state,
                headline: clipEditorialState.headline,
                summary: clipEditorialState.summary,
                userid: clipEditorialState.userid,
                sentiment: clipEditorialState.sentiment
            });
        }
    }

    const addMessageListener = () => {
        // Listen to editorial messages
        messageHandlerRef.current.listen('editorial', messageHandlerListen);
    }

    useEffect(() => {
        return () => {
            messageHandlerRef.current.removeListener('editorial', messageHandlerListen);
        }
    }, [])

    useEffect(() => {
        messageHandlerRef.current.removeListener('editorial', messageHandlerListen);
        addMessageListener();
    },[clipEditorialState.cas])

    const handleCardClick = () => {
        if (backFillSelectionVisible) {
            const isChecked = selectedCardElements.some((item: any) =>
                item.notifID
                    ? item.notifID === clipDetailData.notifid
                    : item.notifid === clipDetailData.notifid
            );
            setSelectedCardElements(
                isChecked
                    ? selectedCardElements.filter((item: any) =>
                          item.notifID
                              ? item.notifID !== clipDetailData.notifid
                              : item.notifid !== clipDetailData.notifid
                      )
                    : [...selectedCardElements, clipDetailData]
            );
        } else {
            if (process.env.REACT_APP_ENV === 'development') {
                clipDetailData.viewLink = removeOrigin(clipDetailData.viewLink);
            }

            const url = isEditorial
                ? `${clipDetailData.viewLink + '&ed=2'}`
                : clipDetailData.viewLink;
        
            const meWindow = window.open(url, 'mediaEditor');
    
            if (isEditorial) {
                // Check if the clipEditorialState is not 'done', 'rejected', or 'dup' before updating the state
                const invalidStates = ['done', 'rejected', 'dup', 'locked'];
                // const isLockedByOtherUser = clipEditorialState.state === 'locked' && clipEditorialState.userid !== meResponse?.name;
                if (!invalidStates.includes(clipEditorialState.state)) {
                    updateClipState(clipDetailData.notifid, clipEditorialState.cas, clipStates.cl, 'take');
                   
                    
                }
                messageHandlerRef.current.setWindow(meWindow);
                // messageHandlerRef.current?.send('editorial', 'set-updates', {
                //     notificationId: clipDetailData.notifid,
                //     clipState: clipEditorialState.state,
                //     headline: clipDetailData.headline,
                //     summary: clipDetailData.summary,
                // });
            }
        }
    };
    

    //Editorial Workflow Color Conditions
    const editorialContainerColor = (clipEditorialState: any) => {
        switch(clipEditorialState.state) {
            case 'locked':
                return 'rgb(255, 255, 150)';
            case 'done':
                return 'rgb(200, 255, 200)';
            case 'dup':
                return 'rgb(221, 221, 221)';
            case 'rejected':
                return 'rgb(170, 170, 170)';
            default:
                return;
        }
    }


    const mergedBasicNotifications = clip.basicNotifications?.map((clipNotif: any) => {
        const matchingNotif = basicNotifications?.find(
            (notif: any) => notif.notifid === clipNotif.notifID
        );
        return matchingNotif ? { ...clipNotif, ...matchingNotif } : clipNotif;
    });

    return (
        <div
            style={
                isEditorial
                    ? { ...style, borderLeft: 'solid 15px' + ' ' + editorialContainerColor(clipEditorialState), boxSizing: 'border-box' }
                    : style
            }
            key={clip.channelid}
            className={styles.clipCardContainer}
            onClick={handleCardClick}
        >
            {backFillSelectionVisible && (
                <input
                    type="checkbox"
                    className={styles.backFillCheckbox}
                    checked={selectedCardElements.some((item: any) =>
                        item.notifID
                            ? item.notifID === clipDetailData.notifid
                            : item.notifid === clipDetailData.notifid
                    )}
                    onClick={handleCheckboxClick}
                    onChange={(e) => e.stopPropagation()}
                />
            )}
            {isDuplicate(clip) && (
                <div
                    title={'This is a duplicate clip of the original clip.'}
                    className={`${styles.duplicateIcon} ${
                        isArabic ? styles.duplicateIconRTL : ''
                    }`}
                >
                    <FontAwesomeIcon icon={faCopy} />
                </div>
            )}
            <img
                width={5}
                src={
                    clipDetailData.mediatype === 'radio'
                        ? defaultRadioKeyframe
                        : clipDetailData.kfLink
                }
                alt="clip thumbnail"
                onError={(e: any) => {
                    e.target.src = defaultKeyframe;
                }}
                className={styles.clipKeyframe}
            />
            <FontAwesomeIcon
                icon={faPlay}
                className={
                    backFillSelectionVisible ? styles.playIconBackFill : styles.playIcon
                }
                // onClick={(e) => {
                //     e.stopPropagation();
                //     window.open(clipDetailData.viewLink, '_blank');
                // }}
            />
            <div className={styles.clipTitleContainer}>
                {isEdited && sentimentIcon && (
                    <span className={styles.sentimentIcon}>{sentimentIcon}</span>
                )}
                {
                    <span className={styles.copyCardDataIcon}>
                        <FontAwesomeIcon
                            icon={faClipboard}
                            onClick={(e) => {
                                e.stopPropagation();
                                copyToClipboard(clipDetailData.desc);
                            }}
                        />
                    </span>
                }
                {/* <hr className={styles.cardHr}/> */}
                <span
                    className={styles.clipTitle}
                    style={isMentionFindrApp ? { fontSize: '0.8rem' } : {}}
                >
                    <div className={styles.timeCodeChannel}>
                        <img
                            src={clipDetailData.icon}
                            width="40"
                            height="20"
                            alt="icon"
                        />
                        <span className={styles.channelName}>
                            {highlightChannelName(
                                clip.channelName,
                                clipDetailData.channel
                            )}
                        </span>
                        <span>{clipDetailData?.beginF}</span>
                        {clip.headline && ' - '}
                        {programTitle && ' - '}
                        <span className={styles.programTitle}>
                            {programTitle}
                            {programTitle && clip.summary && ' - '}
                            {isEdited && (
                                <div
                                    className={styles.summaryHeadlineContainer}
                                    title={clip.headline}
                                >
                                    <span className={styles.editedHeadline}>
                                        {clip.headline
                                            ? clip.headline.slice(0, 95) +
                                              (clip.headline.length > 95 ? '...' : '')
                                            : ''}
                                    </span>
                                    {/* {programTitle && clip.summary && clip.headline && ' - '} */}
                                </div>
                            )}
                        </span>
                        {meResponse.can_AVE === '1' && clipDetailData.aveReach && (
                            <span className={styles.ave}>
                                {` ${clipDetailData.clipDuration} sec | ${Math.trunc(
                                    clipDetailData.clipDuration * clipDetailData.aveRate
                                )} ${clipDetailData.aveCurrency || ''} | ${Math.trunc(
                                    clipDetailData.aveReach
                                )}`}{' '}
                                <FontAwesomeIcon icon={faEye} />
                            </span>
                        )}
                    </div>
                    <div className={styles.flagContainer}>
                        <CountryFlag cc={clip.cc} width={20} title={clip.country} />
                    </div>
                </span>
                {isEdited && (
                    <div className={styles.summaryHeadlineContainer}>
                        <span className={styles.editedSummary} title={clip.summary}>
                            {clip.summary
                                ? clip.summary.slice(0, 400) +
                                  (clip.summary.length > 400 ? '...' : '')
                                : null}
                        </span>
                    </div>
                )}

                {/* {isEdited && <hr className={styles.cardHr} />} */}

                {!isEdited && <span
                    className={isArabic ? styles.clipDescArabic : styles.clipDesc}
                    title={clipDetailData.desc}
                    dangerouslySetInnerHTML={{
                        __html: description
                    }}
                ></span>}
                {showCopiedMessage && (
                    <div className={styles.copiedMessage}>
                        <span>
                            {isEdited ? 'Summary' : 'Description'}{' '}
                            {t('copied to clipboard')}
                        </span>
                    </div>
                )}
                {mergedBasicNotifications && (
                    <div className={styles.basicNotificationsContainer}
                    onClick={(e: any) => (
                        e.stopPropagation()
                    )}
                    >
                        {mergedBasicNotifications
                            // .filter((notification: any) =>
                            //     activeFilter.foldEnabled
                            //         ? notification.dupType === null
                            //         : true
                            // )
                            .map((notification: any) => (
                                <div
                                    key={notification.notifID}
                                    className={`${styles.basicNotificationCard}`} 
                                    // ${
                                    //     notification.dupType !== null
                                    //         ? styles.duplicateNotification
                                    //         : ''
                                    // }
                                    // Apply duplicate class if the notification is a duplicate
                                    // onMouseEnter={() => setTooltipVisible(true)}
                                    // onMouseLeave={() => setTooltipVisible(false)}
                                >
                                    {/* {tooltipVisible && (
                                    <div className={styles.basicNotificationTooltip}>
                                        <p>{notification.title}</p>
                                        
                                    </div>
                                )} */}

                                    {/* Display a duplicate indicator if applicable */}
                                    {/* {notification.dupType !== null && (
                                        <div className={styles.duplicateIndicator}>
                                            <FontAwesomeIcon
                                                icon={faCopy}
                                                title="Duplicate Notification"
                                            />
                                        </div>
                                    )} */}
                                            {/* <img
                                                src={
                                                    notification.mediatype === 'radio'
                                                        ? defaultRadioKeyframe
                                                        : notification.kfLink
                                                }
                                                width={50}
                                                alt="basic notification thumbnail"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    window.open(
                                                        notification.viewLink,
                                                        '_blank'
                                                    );
                                                }}
                                                className={
                                                    styles.basicNotificationThumbnail
                                                }
                                            /> */}

                                        <p className={styles.superTimesContainer}>
                                            <span className={styles.superTimeStamp} 
                                            
                                            onClick={(e) => {
                                                    e.stopPropagation();
                                                    // if (process.env.REACT_APP_ENV === 'development') {
                                                    //     notification.viewLink = removeOrigin(notification.viewLink);
                                                    // }
                                                    // const url = isEditorial
                                                    // ? `${notification.viewLink + '&ed=2'}`
                                                    // : notification.viewLink;
                                                    // window.open(
                                                    //     url,
                                                    //     'mediaEditor'
                                                    // );
                                                }}
                                                >
                                                {convertUnixTimestamp(notification.hitTimeTS)}
                                            </span>
                                            {/* <span
                                            className={styles.basicDescription}
                                                dangerouslySetInnerHTML={{
                                                    __html: notification.desc
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    window.open(
                                                        notification.viewLink,
                                                        '_blank'
                                                    );
                                                }}
                                            ></span> */}
                                        </p>
                                    </div>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ClipCard;
